import { defineStore } from 'pinia'

export const useUserActivityStore = defineStore('userActivityStore', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            events: JSON.parse(localStorage.getItem('ACTIVITY_EVENTS')) || [],
            meeting_events: JSON.parse(localStorage.getItem('MEETING_EVENTS')) || [],
        }
    },
    actions: {
        clearMeetings(){
            localStorage.setItem('MEETING_EVENTS', JSON.stringify([]));
            this.meeting_events = [];
        },
        addEvent(event) {
            try {
                this.events.push(event)
                localStorage.setItem('ACTIVITY_EVENTS', JSON.stringify(this.events));
                if(this.events.length > 20) {
                    axios.post(route('user-log.store'),{
                        events : this.events
                    }).then((response) => {
                        this.events = []
                        localStorage.setItem('ACTIVITY_EVENTS', JSON.stringify(this.events));
                    });
                }
            } catch (error) {
                console.error(error)
            }
        },
        addMeetingEvent(event) {
            try {
                this.meeting_events.push(event)
                localStorage.setItem('MEETING_EVENTS', JSON.stringify(this.meeting_events));
            } catch (error) {
                console.error(error)
            }
        },
    }
})
