import { defineStore } from 'pinia'
import axios from "axios";

export const useAppStore = defineStore('appStore', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            version: JSON.parse(localStorage.getItem('version')) || 0,
            external_dashboards :  JSON.parse(localStorage.getItem('external_dashboards')) || [],
            docket_statuses :  JSON.parse(localStorage.getItem('docket_statuses')) || [],
            docket_stages :  JSON.parse(localStorage.getItem('docket_stages')) || [],
            docket_priorities :  JSON.parse(localStorage.getItem('docket_priorities')) || [],
            docket_categories : JSON.parse(localStorage.getItem('docket_categories')) || [],
            docket_tags : JSON.parse(localStorage.getItem('docket_tags')) || [],
            task_statuses :  JSON.parse(localStorage.getItem('task_statuses')) || [],
            task_priorities :  JSON.parse(localStorage.getItem('task_priorities')) || [],
            task_risk_factors :  JSON.parse(localStorage.getItem('task_risk_factors')) || [],
            company_level_descriptions :  JSON.parse(localStorage.getItem('company_level_descriptions')) || [],
            company_level_fives :  JSON.parse(localStorage.getItem('company_level_fives')) || [],
        }
    },
    actions: {
        async getVersion() {
            axios.get(route('ajax.app-version.index'))
                .then(response => {
                    if(this.version !== response.data){
                        console.log("Current Version: "+this.version);
                        console.log("Received Version: "+response.data);
                        this.version = response.data;
                        localStorage.setItem('version', JSON.stringify(this.version));
                        this.getAppStore();
                    }

                }).catch(error => {
                console.log(error);
            });
        },
        async getAppStore() {
            axios.get(route('ajax.app-menu.index'))
                .then(response => {
                    this.external_dashboards = response.data.external_dashboards;
                    this.docket_statuses = response.data.docket_statuses;
                    this.docket_stages = response.data.docket_stages;
                    this.docket_priorities = response.data.docket_priorities;
                    this.task_statuses = response.data.task_statuses;
                    this.task_priorities = response.data.task_priorities;
                    this.task_risk_factors = response.data.task_risk_factors;
                    this.company_level_descriptions = response.data.company_level_descriptions;
                    this.company_level_fives = response.data.company_level_fives;
                    this.docket_categories = response.data.docket_categories;
                    this.docket_tags = response.data.docket_tags;
                    localStorage.setItem('external_dashboards', JSON.stringify(this.external_dashboards));
                    localStorage.setItem('docket_statuses', JSON.stringify(this.docket_statuses));
                    localStorage.setItem('docket_stages', JSON.stringify(this.docket_stages));
                    localStorage.setItem('docket_priorities', JSON.stringify(this.docket_priorities));
                    localStorage.setItem('task_statuses', JSON.stringify(this.task_statuses));
                    localStorage.setItem('task_priorities', JSON.stringify(this.task_priorities));
                    localStorage.setItem('task_risk_factors', JSON.stringify(this.task_risk_factors));
                    localStorage.setItem('company_level_descriptions', JSON.stringify(this.company_level_descriptions));
                    localStorage.setItem('company_level_fives', JSON.stringify(this.company_level_fives));
                    localStorage.setItem('docket_categories', JSON.stringify(this.docket_categories));
                    localStorage.setItem('docket_tags', JSON.stringify(this.docket_tags));
                    window.location.reload();
                }).catch(error => {
                console.log(error);
            });
        }
    }
})
