import { defineStore } from 'pinia'

export const useGalleryStore = defineStore('galleryStore', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            item: JSON.parse(localStorage.getItem('GALLERY_ITEM')) || '',
            item_action : JSON.parse(localStorage.getItem('GALLERY_ITEM_ACTION')) || '',
            item_type : JSON.parse(localStorage.getItem('GALLERY_ITEM_TYPE')) || '',
        }
    },
    actions: {
        removeItem() {
            localStorage.removeItem('GALLERY_ITEM');
            localStorage.removeItem('GALLERY_ITEM_ACTION');
            localStorage.removeItem('GALLERY_ITEM_TYPE');
            console.log("Running remove item");
            this.item = '';
            this.item_action = '';
            this.item_type = '';
        },
        addItem(event) {
            try {
                this.item = event;
                localStorage.setItem('GALLERY_ITEM', JSON.stringify(this.item));
            } catch (error) {
                console.error(error)
            }
        },
        setAction(event) {
            try {
                this.item_action = event;
                localStorage.setItem('GALLERY_ITEM_ACTION', JSON.stringify(this.item_action));
            } catch (error) {
                console.error(error)
            }
        },
        setType(event) {
            try {
                this.item_type = event;
                localStorage.setItem('GALLERY_ITEM_TYPE', JSON.stringify(this.item_type));
            } catch (error) {
                console.error(error)
            }
        },
        start(){
            console.log("Gallery Store Started");
        }

    }
})
